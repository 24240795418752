import Axios from 'axios'
import { required } from "vuelidate/lib/validators"

export const AreaServices = {
    //API
    api: {
        async find(id) {
            return Axios.get('areas/' + id)
                .then((response) => {
                    return response.data
                })
                .catch((error) => { throw error })
        },
        async getAll() {
            return Axios.get('areas')
                .then((response) => {
                    return response.data
                })
                .catch((error) => {
                    throw error
                })
        },
        async getAllForGuardias() {
            return Axios.get('areas/for-guardias')
              .then((response) => {
                  return response.data
              })
              .catch((error) => {
                  throw error
              })
        },
        async save(area) {
            return Axios.post('areas', area)
                .then((response) => {
                    return response.data
                })
                .catch((error) => {
                    throw error
                })
        },
        async put(id, area) {
            return Axios.put('areas/' + id, area)
                .then((response) => {
                    return response.data
                })
                .catch((error) => {
                    throw error
                })
        },
        async delete(area) {
            return Axios.delete('areas/' + area.id)
                .then((response) => {
                    return response.data
                })
                .catch((error) => {
                    throw error
                })
        },
    },
    //VALIDATIONS
    validations: {
        descripcion: { required },
        gerencia_id: { required },
    },
    //TABLELIST
    headers() {
        const header = [{ value: '#', sortable: true }, { value: 'Área', sortable: true }, { value: 'Gerencia', sortable: true }, { value: 'Jefe', sortable: true }]
        return header;
    },
    body() {
        const body = [{
                class: 'w-100',
                data: [{ value: 'id', class: 'text-primary', label: '#' }]
            },
            {
                class: 'important',
                data: [{ value: 'descripcion' }]
            },
            {
                class: 'w-100',
                data: [{ value: 'gerencia.descripcion', label: 'Gerencia' }]
            },
            {
                class: 'w-100',
                data: [{ value: 'jefe.nombre', label: 'Jefe' }]
            }
        ]
        return body
    },
    dataList() {
        return [
            { name: "descripcion", class: "" }, { name: "gerencia.descripcion", label: 'Gerencia', class: "text-secondary" }
        ]
    }

}
